import { Component, Inject, OnInit } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { SharedModule } from '../../_shared/shared.module';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { EntrySectionInterface } from '../../../_generated/api_interfaces/api/entrySection.interface';
import { ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EventInterface } from '../../../_generated/api_interfaces/api/event.interface';
import { EntryDivisionInterface } from '../../../_generated/api_interfaces/api/entryDivision.interface';
import { catchError } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-competitions',
    standalone: true,
    imports: [AsyncPipe, SharedModule, NgIf, NgForOf, KeyValuePipe, RouterLink, DatePipe, CurrencyPipe, FormsModule],
    templateUrl: './competitions.component.html',
    styleUrl: './competitions.component.scss',
})
export class CompetitionsComponent implements OnInit {
    public sectionTypes$: Observable<ReturnType<EntrySectionInterface['getSections']>>;
    public event$: Observable<ReturnType<EventInterface['apiEventsIdGet']>>;
    public division: Observable<ReturnType<EntryDivisionInterface['getDivisions']>>;
    public sectionCode: string;
    public selectedSectionCode: string = '';
    public sections: any[] = [];
    public eventId: string;
    public loggedIn: boolean = false;
    public selectedSection: Observable<ReturnType<EntrySectionInterface['getSection']>>;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        private route: ActivatedRoute,
        @Inject('UserService') public user: UserServiceInterface,
    ) {}

    ngOnInit(): void {
        // Get the current event
        this.event$ = this.api.get({
            url: '/api/v1/events/current',
            displayErrors: false,
            useCache: false,
            bypassOfflineQueue: true,
            skipAuth: true,
        });

        this.user.watch.subscribe(user => {
            this.loggedIn = user.loggedIn;
        });

        this.sectionTypes$ = this.event$.pipe(
            switchMap(event => {
                if (!event?.data?.id) {
                    // Handle the case where event or event.data.id is null/undefined
                    return throwError(() => new Error('Event data is missing or invalid.'));
                }

                this.eventId = event.data.id;

                this.route.paramMap.subscribe(params => {
                    this.sectionCode = params.get('sectionCode') ? params.get('sectionCode').toUpperCase() : '';

                    // Optionally, if the section ID is set, highlight or load it
                    if (this.sectionCode) {
                        this.setSectionId(this.sectionCode);
                    }
                });

                return this.api.get({
                    url: `/api/public/entry_section/byType?event.ulid=${event.data.id}`, // Use event.data.id here safely
                    displayErrors: false,
                    useCache: false,
                    refresh: false,
                    skipAuth: true,
                    bypassOfflineQueue: true,
                });
            }),
            catchError(err => {
                console.error('Error while fetching section types:', err);
                return of(null); // Or any fallback Observable
            }),
        );
    }

    onSectionChange(event: Event) {
        const selectedCode = (event.target as HTMLSelectElement).value;
        this.setSectionId(selectedCode);
    }

    public setSectionId(sectionCode: string): void {
        this.sectionCode = sectionCode;
        this.fetchSelectedSection();
    }

    public sanitizeHtml(rules: string): string {
        return rules.replace(/&nbsp;/g, ' '); // Replace non-breaking spaces with regular spaces
    }

    private fetchSelectedSection() {
        this.selectedSection = this.api
            .get({
                url: `/api/public/entry_section/withDivisions/${this.sectionCode}/${this.eventId}`,
                displayErrors: false,
                skipAuth: true,
                bypassOfflineQueue: true,
            })
            .pipe(
                catchError(error => {
                    console.error('Error fetching section:', error);
                    return of(null); // Return a fallback value
                }),
            );
    }
}
