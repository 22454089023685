import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiErrorResponse, ApiResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { passwordMatchValidator } from '../../../../../validators/password-match.validator';

@Component({
    selector: 'app-register-user',
    templateUrl: './register-user.component.html',
    styleUrls: ['./register-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterUserComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public signUpForm: FormGroup;

    public error?: string;
    public success?: boolean = false;
    public loading: boolean = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        @Inject('UserService') private userService: UserServiceInterface,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.signUpForm = this.formBuilder.group(
            {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(10)]],
                repeatPassword: ['', [Validators.required]],
                contactType: ['family'],
                recaptchaToken: ['', [Validators.required]],
            },
            {
                validator: passwordMatchValidator('password', 'repeatPassword'),
            },
        );
    }

    public newRegistration(): void {
        if (this.signUpForm.valid) {
            this.loading = true;
            const formData = this.signUpForm.value;
            this.api.post({ url: '/api/user/register', data: formData, skipAuth: true }).subscribe(
                (res: ApiResponse<any>) => {
                    this.success = true;
                    this.error = null;
                    this.cdRef.detectChanges();
                },
                (err: ApiErrorResponse<any>) => {
                    this.loading = false;
                    this.error = err.error.error;
                    this.cdRef.detectChanges();
                },
            );
        }
    }

    // Custom validator function for password matching
    passwordMatchValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected readonly environment = environment;
}
