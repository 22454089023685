import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormDateComponent } from './components/forms/custom-fields/sf-form-date/sf-form-date.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserFormComponent } from './components/forms/custom-forms/user-form/user-form.component';
import { SfFormDatetimeComponent } from './components/forms/custom-fields/sf-form-datetime/sf-form-datetime.component';
import { SfFormTimeComponent } from './components/forms/custom-fields/sf-form-time/sf-form-time.component';
import { Ngxh6Module, NotificationBadgeComponent, SfFormModule } from '@hutsix/ngxh6';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { LoginComponent } from './routes/user/login/login.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { ReloadPageComponent } from './routes/reload-page/reload-page.component';
import { AccountsNavbarComponent } from './components/ui/accounts-navbar/accounts-navbar.component';
import { FamilyTableComponent } from './components/tables/family-table/family-table.component';
import { HorsesTableComponent } from './components/tables/horses-table/horses-table.component';
import { GradesTableComponent } from './components/tables/grades-table/grades-table.component';
import { EventSelectorComponent } from './components/ui/event-selector/event-selector.component';
import { CartComponent } from './components/ui/cart/cart.component';
import { CartTableComponent } from './components/tables/cart-table/cart-table.component';
import { OrdersTableComponent } from './components/tables/orders-table/orders-table.component';
import { SectionInformationComponent } from './routes/section-information/section-information.component';
import { ItemTableComponent } from './components/tables/item-table/item-table.component';
import { ForgotPasswordComponent } from './routes/user/forgot-password/forgot-password.component';
import { RegisterUserComponent } from './routes/user/register-user/register-user.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ResetPasswordComponent } from './routes/user/forgot-password/reset-password/reset-password.component';
import { HorseStallsTableComponent } from './components/tables/horse-stalls-table/horse-stalls-table.component';
import { TicketsTableComponent } from './components/tables/tickets-table/tickets-table.component';
import { EntriesTableComponent } from './components/tables/entries-table/entries-table.component';
import { GateSalesTableComponent } from './components/tables/gate-sales-table/gate-sales-table.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: '#',
    maxFilesize: 50,
    // acceptedFiles: '',
    maxFiles: 1,
    addRemoveLinks: true,
    autoQueue: false,
    autoProcessQueue: false,
    uploadMultiple: false,
    headers: {
        'Cache-Control': '',
        'X-Requested-With': '',
    },
    method: 'post',
};

@NgModule({
    // eslint-disable-next-line prettier/prettier
    declarations: [
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        UserFormComponent,
        NavbarComponent,
        LoginComponent,
        DashboardComponent,
        ReloadPageComponent,
        AccountsNavbarComponent,
        FamilyTableComponent,
        HorsesTableComponent,
        GradesTableComponent,
        EventSelectorComponent,
        CartComponent,
        CartTableComponent,
        OrdersTableComponent,
        SectionInformationComponent,
        ItemTableComponent,
        ForgotPasswordComponent,
        RegisterUserComponent,
        ResetPasswordComponent,
        HorseStallsTableComponent,
        TicketsTableComponent,
        GateSalesTableComponent,
        EntriesTableComponent,
    ],

    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        // To be exported
        Ngxh6Module,
        SfFormModule.forRoot({
            customTypes: [
                { prefix: 'date', component: SfFormDateComponent },
                { prefix: 'datetime', component: SfFormDatetimeComponent },
                { prefix: 'time', component: SfFormTimeComponent },
            ],
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            newestOnTop: false,
            enableHtml: false,
            // timeOut: 1000,
        }),
        DropzoneModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
            },
        }),
        NotificationBadgeComponent,
        NgbModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule,
    ],
    exports: [
        // Declared
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        NavbarComponent,

        // IMPORTS:
        Ngxh6Module,
        SfFormModule,
        AccountsNavbarComponent,
        EventSelectorComponent,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
})
export class SharedModule {}
