<div class="content-container m-0">
    <div class="d-flex my-4">
        <div class="login-form mx-auto">
            <form (ngSubmit)="doSignin()" #signinForm="ngForm">
                <div class="d-flex mb-3 flex-column">
                    <img class="logo p-4" src="/assets/logo-large.png" alt="App Logo" />
                </div>

                <div class="alert alert-danger text-break" role="alert" *ngIf="error">Error: {{ error }}</div>

                <div class="form-group">
                    <label class="form-label" for="signin_email">Email</label>
                    <input class="form-control" type="text" id="signin_email" name="email" required [(ngModel)]="signin.email" autocomplete="username" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="signin_pass">Password</label>
                    <input
                        class="form-control"
                        type="password"
                        id="signin_pass"
                        name="password"
                        required
                        [(ngModel)]="signin.password"
                        autocomplete="current-password"
                    />
                </div>

                <div class="d-flex">
                    <input type="submit" value="Sign In" class="btn btn-primary ms-0 mb-4" [disabled]="!signinForm.form.valid || loading" />
                </div>

                <div class="form-group">
                    <p class="text-muted text-center">Or, just need tickets?</p>
                    <button type="button" class="btn btn-primary ms-0 mb-4" [disabled]="loading" (click)="openGuestCheckoutModal()">Continue as guest</button>
                </div>

                <p class="my-0 small text-center"><a routerLink="/register">Register</a> | <a routerLink="/forgot-password">Forgot Password?</a></p>
            </form>
        </div>
    </div>
</div>

<ng-template #guestCheckoutModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Important Notice!</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <p>Guest checkouts are only for purchasing admission tickets, without an account you cannot:</p>
            <ul>
                <li>Submit nominations/ entries</li>
                <li>Purchase horse stalls</li>
                <li>View invoices</li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="modal.close()">Cancel</button>
        <button type="button" class="col-2 btn btn-primary" (click)="modal.close(); createGuestUser()">Ok</button>
    </div>
</ng-template>
