import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  tickets: {
    tableSpec: {
      actionLabel: 'View Order',
      columns: [
        {
          header: 'Type',
          type: 'simple',
          value: 'string',
        },
        {
          header: 'Contact',
          type: 'objvalue',
          typeOption: 'string',
          value: 'createdBy',
        },
        {
          header: 'Creation Date',
          type: 'date',
          typeOption: 'd MMM YYYY',
          value: 'created',
        },
      ],
    },
  },
  ticket_types: {
    tableSpec: {
      columns: [
        {
          header: 'Ticket',
          value: 'label',
        },
        {
          header: 'Price if purchasable',
          type: 'currency',
          value: 'price',
        },
        {
          header: 'Is a 2 Day Pass',
          type: 'boolean',
          value: 'twoDayPass',
        },
        {
          header: 'Available for gate sale',
          type: 'boolean',
          value: 'gateSale',
        },
        {
          header: 'Req. Car Registration',
          type: 'boolean',
          value: 'requiresCarRegistration',
        },
        {
          header: 'Published Status',
          type: 'customFunction',
          typeOption: (item: any) => {
            const now = new Date();
            if (
              item.availableFrom === undefined ||
              (now >= new Date(item.availableFrom) && item.availableUntil === undefined) ||
              now <= new Date(item.availableUntil)
            ) {
              return 'Published';
            } else {
              return 'Unpublished';
            }
          },
          value: '',
        },
      ],
    },
  },
  entry_prize_sets: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          value: 'name',
        },
        {
          header: 'Prizes',
          type: 'arraypluck',
          typeOption: 'string',
          value: 'prizes',
        },
      ],
    },
  },
  entries: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          value: 'string',
        },
      ],
    },
  },
  entry_prize_types: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          value: 'description',
        },
        {
          header: 'Prize Card',
          type: 'boolean',
          value: 'prizeCard',
        },
        {
          header: 'Title (eg on Card, if different)',
          value: 'title',
        },
        {
          header: 'Eligible for Special Prize',
          type: 'boolean',
          value: 'eligibleForSpecialPrize',
        },
        {
          header: 'Is Special Prize',
          type: 'boolean',
          value: 'specialPrize',
        },
      ],
    },
  },
  horse_stalls: {
    tableSpec: {
      actionUrl: '/',
      actionLabel: 'View Order',
      columns: [
        {
          header: 'Type',
          type: 'objvalue',
          typeOption: 'string',
          value: 'horseStallType',
        },
        {
          header: 'Horse',
          type: 'objvalue',
          typeOption: 'name',
          value: 'horse',
        },
        {
          header: 'Horse Height',
          type: 'objvalue',
          typeOption: 'height',
          value: 'horse',
        },
        {
          header: 'Horse Sex',
          type: 'objvalue',
          typeOption: 'sexLabel',
          value: 'horse',
        },
        {
          header: 'Contact',
          type: 'nested',
          typeOption: ['userAccount', 'name'],
          value: 'horse',
        },
        {
          header: 'Phone',
          type: 'simple', // TODO change to nested
          //typeOption: ['userAccount', 'email'],
          value: 'horse',
        },
      ],
    },
  },
  horse_stall_types: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
        {
          header: 'Required Advantage Pass',
          type: 'arraypluck',
          typeOption: 'string',
          value: 'membershipBundleTypes',
        },
        {
          header: 'Horse Sex',
          type: 'array',
          value: 'horseSexText',
        },
        {
          header: 'Price',
          type: 'currency',
          value: 'price',
        },
      ],
    },
  },
  membership_bundle_types: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
        },
        {
          header: 'Includes',
          type: 'simple',
          value: 'includes',
        },
        {
          header: 'Price',
          type: 'currency',
          value: 'price',
        },
        {
          header: 'Purchasable Online',
          type: 'boolean',
          value: 'purchasableOnline',
        },
      ],
    },
  },
  events: {
    tableSpec: {
      actionUrl: './',
      actionLabel: 'View',
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'name',
          class: 'col-9',
        },
        {
          header: 'Current',
          type: 'boolean',
          value: 'isCurrent',
        },
      ],
    },
  },
  entry_sections: {
    tableSpec: {
      columns: [
        {
          header: 'Name',
          type: 'simple',
          value: 'label',
        },
        {
          header: 'Code',
          type: 'simple',
          value: 'code',
        },
        {
          header: 'Type',
          type: 'simple',
          value: 'type',
        },
        {
          header: 'Divisions',
          type: 'arraypluck',
          typeOption: 'string',
          value: 'divisions',
          class: 'col-8',
        },
      ],
    },
  },
  examples: {
    icon: 'fa-object-group',
    tableSpec: {
      actionUrl: './',
      columns: [
        {
          header: '',
          type: 'usericon',
          value: 'createdBy',
          style: { 'min-width': '50px' },
        },
        {
          header: 'Created By',
          type: 'objvalue',
          typeOption: 'name',
          value: 'createdBy',
          style: { 'min-width': '120px' },
          class: 'text-nowrap',
        },
        { header: 'Number', value: 'number', class: 'text-nowrap' },
        { header: 'Name', value: 'name', class: 'text-nowrap', linkAction: true },
        { header: 'Category', type: 'objvalue', typeOption: 'name', value: 'category', class: 'text-nowrap' },
        {
          header: 'Date',
          type: 'date',
          typeOption: 'short',
          value: 'created',
          class: 'text-nowrap',
        },
        {
          header: 'Array',
          type: 'arraypluck',
          typeOption: 'name',
          value: 'exampleCollection',
          style: { 'width': '100%', 'min-width': '150px' },
        },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
