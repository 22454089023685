<app-navbar></app-navbar>
<div class="d-flex flex-row page-content">
    <div class="sidebar sidebar-competition toggle d-none d-lg-block">
        <nav class="sidebar-nav">
            <ul class="nav pt-4">
                <div *ngIf="sectionTypes$ | async as sectionTypes; else loadingOrError" class="">
                    <div class="p-3 text-center" *ngIf="!sectionTypes.data">Something went wrong sections, try again later.</div>
                    <ul *ngFor="let sections of sectionTypes.data | keyvalue" class="mb-2">
                        <li class="sidebar-header">{{ sections.key }}</li>
                        <li *ngFor="let section of sections.value" class="sidebar-item">
                            <a
                                [routerLink]="['/competitions', section.code.toLowerCase()]"
                                [class.active]="sectionCode === section.code"
                                class="sidebar-link d-flex justify-content-between"
                                (click)="setSectionId(section.code)"
                            >
                                <span>
                                    <strong>{{ section.code }}</strong> - {{ section.label }}
                                </span>
                                <div class="badge-container d-flex align-items-center d-none">
                                    <div class="badge text-bg-light">{{ section.divisions.length }}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </ul>
        </nav>
    </div>

    <div class="d-flex section-content-outer flex-grow-1 flex-column">
        <div class="form-group select-box p-3">
            <label for="section-select">Select a Section</label>
            <select id="section-select" class="form-select" [(ngModel)]="selectedSectionCode" (change)="onSectionChange($event)">
                <option value="" disabled selected>Choose a section</option>

                <ng-container *ngIf="sectionTypes$ | async as sectionTypes; else loadingOrError">
                    <optgroup *ngFor="let sections of sectionTypes.data | keyvalue" [label]="sections.key">
                        <option *ngFor="let section of sections.value" [value]="section.code" [selected]="sectionCode === section.code">
                            {{ section.code }} - {{ section.label }}
                        </option>
                    </optgroup>
                </ng-container>
            </select>
        </div>

        <div *ngIf="selectedSection | async as section; else noSectionFound">
            @if (section.data.coverPhoto) {
            <div class="cover-photo-container" [style.background-image]="'url(' + section.data.coverPhoto.string + ')'"></div>
            }
            <div class="d-flex flex-grow-1 flex-column p-1 p-lg-5 pt-lg-2 pt-0">
                <div class="content-container m-2" [class.section-content-container]="section.data.coverPhoto">
                    <h2 class="my-2 section-title text-center">{{ section.data.code }} - {{ section.data.label }}</h2>
                    @if (section.data.juniorOnly) {
                    <p>Juniors Only</p>
                    } @if (section.data.membersOnly) {
                    <p>Associates & Members Only</p>
                    } @if (section.data.judges.length > 0) {
                    <p>
                        <strong>{{ section.data.judges.length === 1 ? 'Judge' : 'Judges' }}:</strong>
                        <ng-container *ngFor="let judge of section.data.judges; let i = index">
                            {{ judge.name }}<span *ngIf="i < section.data.judges.length - 1">, </span>
                        </ng-container>
                    </p>
                    } @if (section.data.stewards.length > 0) {
                    <p>
                        <strong>{{ section.data.stewards.length === 1 ? 'Steward' : 'Stewards' }}:</strong>
                        <ng-container *ngFor="let steward of section.data.stewards; let i = index">
                            {{ steward.name }}<span *ngIf="i < section.data.stewards.length - 1">, </span>
                        </ng-container>
                    </p>
                    }

                    <div class="d-flex gap-3">
                        @if (section.data.fee || section.data.feeMember) {
                        <div class="section-container section-container-fees">
                            <div class="section-container-header">
                                <h4><i class="fa fa-dollar mr-2"></i> Entry Fees</h4>
                            </div>
                            <div class="section-container-body">
                                @if (section.data.feeMember) {
                                <div>
                                    <span class="comma">Associates & Members: ${{ section.data.feeMember }}</span>
                                </div>
                                } @if (section.data.fee) {
                                <div>
                                    <span class="comma">Non Associates & Non Members: ${{ section.data.fee }}</span>
                                </div>
                                }
                            </div>
                        </div>
                        }
                        <div class="section-container flex-fill">
                            <div class="section-container-header">
                                <h4><i class="fa fa-calendar mr-2"></i> Key Dates</h4>
                            </div>
                            <div class="section-container-body">
                                @if (section.data.closingDateOnline) {
                                <p class="mb-0">
                                    <strong>Entries Close:</strong>
                                    {{ section.data.closingDateOnline | date: 'fullDate' }}
                                </p>
                                } @if (section.data.deliveryDate) {
                                <p class="mb-0">
                                    <strong>Delivery:</strong>

                                    {{ section.data.deliveryDate | date: 'fullDate' }}
                                    {{ section.data.deliveryNote ? ' - ' + section.data.deliveryNote : '' }}
                                </p>
                                } @if (section.data.collectionDate) {
                                <p class="mb-0">
                                    <strong>Collection:</strong>
                                    {{ section.data.collectionDate | date: 'fullDate' }}
                                    {{ section.data.collectionNote ? ' - ' + section.data.collectionNote : '' }}
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-3">
                        @if (section.data.prizeSet) {
                        <div class="section-container section-container-fees d-none">
                            <div class="section-container-header">
                                <h4><i class="fa fa-trophy mr-2"></i> Sponsors & Prizes</h4>
                            </div>
                            <div class="section-container-body">
                                @if (section.data.prizeSet.name?.length) {
                                <p>{{ section.data.prizeSet.name }}</p>
                                } @if (section.data.prizeSet.prizes?.length) {
                                <div *ngFor="let prize of section.data.prizeSet.prizes">
                                    {{ prize.string }}:
                                    <strong>{{ prize.amount | currency: 'AUD':'$' }}</strong>
                                </div>
                                }
                            </div>
                        </div>
                        } @if (section.data.specialPrizes.length > 0) {
                        <div class="section-container flex-fill">
                            <div class="section-container-header">
                                <h4><i class="fa fa-star mr-2"></i> Special Awards</h4>
                            </div>
                            <div class="section-container-body">
                                @if (section.data.specialPrizes?.length) {
                                <div *ngFor="let prize of section.data.specialPrizes">
                                    <strong>{{ prize.string }}</strong> - {{ prize.specialPrizeNotes }}
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    @if (section.data.rules) {
                    <div class="section-container flex-fill">
                        <div class="section-container-header">
                            <h4><i class="fa fa-star mr-2"></i> Rules</h4>
                        </div>
                        <div
                            class="section-container-body"
                            [innerHTML]="sanitizeHtml(section.data.rules)"
                        ></div>
                    </div>
                    }
                    <h2 class="mb-2 mt-2">Classes</h2>

                    <div class="row top section.data-list">
                        <div class="small-12 columns">
                            <div ngbAccordion>
                                @for (division of section.data.divisions; track division.id) {
                                <div ngbAccordionItem [collapsed]="true">
                                    <h2 ngbAccordionHeader>
                                        <button ngbAccordionButton>
                                            <strong>{{ division.string }} </strong>
                                            <span class="px-2">({{ division.classes.length }} class<span *ngIf="division.classes.length !== 1">es</span>)</span>
                                        </button>
                                    </h2>
                                    <div ngbAccordionCollapse>
                                        <div ngbAccordionBody>
                                            <ng-template>
                                                @if (!division.classes.length > 0) {
                                                <p>No classes found for {{ division.string }}</p>
                                                } @if (division.rulesOverride && division.rules) {
                                                <strong>Rules</strong>
                                                <div [innerHTML]="sanitizeHtml(division.rules)"></div>
                                                } @for (divisionClass of division.classes; track divisionClass.id) {
                                                <div class="section-container flex-fill">
                                                    <div class="section-container-header d-flex justify-content-between">
                                                        <h5 class="mb-0">{{ divisionClass.string }}</h5>

                                                        @if (this.user.isGranted('ROLE_STAFF')){
                                                        <p class="mb-0">Staff cannot enter competitions</p>
                                                        } @else if (loggedIn) {
                                                        <a
                                                            class="btn btn-primary"
                                                            [routerLink]="'/account/entries/' + section.data.id + '/' + division.id + '/' + divisionClass.id"
                                                            >Enter now</a
                                                        >
                                                        } @else {
                                                        <a class="btn btn-primary" [routerLink]="'/'">Log in or register to enter</a>
                                                        }
                                                    </div>
                                                    <div class="section-container-body">
                                                        <div *ngIf="divisionClass.instructions?.length" class="small-4 columns">
                                                            <strong>Instructions: </strong>
                                                        </div>
                                                        <div *ngIf="divisionClass.instructions?.length" class="small-4 columns">
                                                            {{ divisionClass.instructions }}&nbsp;
                                                        </div>
                                                        <div *ngIf="divisionClass.groupPrizeSet || divisionClass.prizeSet" class="small-4 columns">
                                                            <strong>Sponsors & Prizes: </strong>
                                                        </div>
                                                        @if (divisionClass.groupPrizeSet) {
                                                        <ng-container *ngIf="divisionClass.groupPrizeSet.name?.length">
                                                            {{ divisionClass.groupPrizeSet.name }}:<br />
                                                        </ng-container>
                                                        @if (divisionClass.groupPrizeSet.prizes?.length > 0) {
                                                        <div *ngFor="let prize of divisionClass.groupPrizeSet.prizes">
                                                            @if(prize.string) {
                                                            {{ prize.string }}:
                                                            <strong>{{ prize.amount | currency: 'AUD':'$' }}</strong>
                                                            }
                                                        </div>
                                                        } } @else if (divisionClass.prizeSet) {
                                                        <ng-container *ngIf="divisionClass.prizeSet.name?.length > 0">
                                                            {{ divisionClass.prizeSet.name }}:<br
                                                        /></ng-container>

                                                        @if (divisionClass.prizeSet.prizes?.length) {
                                                        <div *ngFor="let prize of divisionClass.prizeSet.prizes">
                                                            {{ prize.string }}:
                                                            <strong>{{ prize.amount | currency: 'AUD':'$' }}</strong>
                                                        </div>
                                                        } } @if (divisionClass.rulesOverride && divisionClass.rules) {
                                                        <strong>Rules</strong>
                                                        <div [innerHTML]="sanitizeHtml(divisionClass.rules)"></div>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-grow-1 flex-column">
            <div class="d-flex flex-grow-1 flex-column p-1 p-lg-5 pt-lg-2 pt-0">
                <ng-template #loadingOrError>
                    <ngxh6-loading-or-error></ngxh6-loading-or-error>
                </ng-template>
                <ng-template #noSectionFound>
                    <div class="content-container m-lg-5 m-2 mt-lg-2">
                        <p *ngIf="!selectedSection">
                            To begin, select a section from the options above <span class="d-none d-lg-inline"> or from the side menu</span>
                        </p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
