import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './modules/_shared/routes/user/login/login.component';
import { AuthGuardService } from './modules/_shared/services/auth-guard.service';
import { ReloadPageComponent } from './modules/_shared/routes/reload-page/reload-page.component';
import { AppRoutes, DebugModule, NotAuthorizedComponent, PageNotFoundComponent } from '@hutsix/ngxh6';
import { RedirectGuardService } from './modules/_shared/services/redirect-guard.service';
import { DashboardComponent } from './modules/_shared/routes/dashboard/dashboard.component';
import { SectionInformationComponent } from './modules/_shared/routes/section-information/section-information.component';
import { ForgotPasswordComponent } from './modules/_shared/routes/user/forgot-password/forgot-password.component';
import { RegisterUserComponent } from './modules/_shared/routes/user/register-user/register-user.component';
import { ResetPasswordComponent } from './modules/_shared/routes/user/forgot-password/reset-password/reset-password.component';
import { CompetitionsComponent } from './modules/public/competitions/competitions.component';

const routes: AppRoutes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'competitions', component: CompetitionsComponent },
    { path: 'competitions/:sectionCode', component: CompetitionsComponent },
    { path: 'register', component: RegisterUserComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
    { path: 'forgot-password/password-reset', component: ResetPasswordComponent, pathMatch: 'full' },
    { path: 'forgot-password/password-reset/:token', component: ResetPasswordComponent },
    { path: 'content/section/:nbr', component: SectionInformationComponent },

    // { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },
    { path: '', canActivate: [RedirectGuardService], component: DashboardComponent },

    {
        path: 'crm',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_STAFF'] },
        loadChildren: () => import('./modules/crm/crm.module').then(m => m.CrmModule),
    },
    {
        path: 'account',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_USER'] },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'user-admin',
        loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule),
    },
    {
        path: 'debug',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_ADMIN'] },
        loadChildren: () => DebugModule,
    },
    { path: 'unauthorised', canActivate: [AuthGuardService], component: NotAuthorizedComponent },
    { path: '**', canActivate: [AuthGuardService], component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
