import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import packageInfo from '../../../../../../../package.json';
import { environment } from '../../../../../../environments/environment';
import { Observable, timer } from 'rxjs';
import {
    ApiServiceInterface,
    FormServiceInterface,
    OfflineQueue,
    OfflineServiceInterface,
    PushServiceInterface,
    ThemeServiceInterface,
    UserAccountInterface,
    UserServiceInterface,
} from '@hutsix/ngxh6';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
    public env: string = environment.env;
    public version: string = packageInfo.version + (environment.env === 'staging' ? '-staging' : '');
    public _user: { loggedIn: boolean; account?: UserAccountInterface };
    public offlineQueue$: Observable<OfflineQueue[]>;

    constructor(
        public router: Router,
        public toastr: ToastrService,
        @Inject('UserService') public userService: UserServiceInterface,
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('OfflineService') public offline: OfflineServiceInterface,
        @Inject('PushService') public push: PushServiceInterface,
        @Inject('ApiService') public api: ApiServiceInterface,
        @Inject('ThemeService') public themeService: ThemeServiceInterface,
        public cdRef: ChangeDetectorRef,
    ) {
        if (this._user) {
            this.offlineQueue$ = this.offline.getOfflineQueue();
        }
    }

    update(): void {
        if (this._user) {
            this.offlineQueue$ = this.offline.getOfflineQueue();
        }
    }

    clearCaches(): void {
        if (!confirm('All cached data will be removed. You will be required to re-sync to use offline capabilities. Are you sure?')) return;

        this.api.clearCaches().subscribe(res => {
            if (res) {
                this.toastr.success('Caches cleared!');
            } else {
                this.toastr.error('Try again.', 'Something went wrong.');
            }
        });
    }
}
