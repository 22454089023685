<div class="content-container m-0">
    <div class="d-flex my-4">
        <div class="login-form mx-auto">
            <form [formGroup]="signUpForm" (ngSubmit)="newRegistration()">
                <h3>Register new account</h3>
                <div class="alert alert-danger text-break" role="alert" *ngIf="error">{{ error }}</div>

                <ng-container *ngIf="!success">
                    <div class="form-group">
                        <label class="form-label" for="register_first_name">First Name</label>
                        <input class="form-control" type="text" id="register_first_name" name="firstName" formControlName="firstName" />
                        <div *ngIf="signUpForm.get('firstName').invalid && signUpForm.get('firstName').touched" class="col-12 col-sm-8">
                            <small *ngIf="signUpForm.get('firstName').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="register_last_name">Last Name</label>
                        <input class="form-control" type="text" id="register_last_name" name="lastName" formControlName="lastName" />
                        <div *ngIf="signUpForm.get('lastName').invalid && signUpForm.get('lastName').touched" class="col-12 col-sm-8">
                            <small *ngIf="signUpForm.get('lastName').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="register_email">Email</label>
                        <input class="form-control" type="email" id="register_email" name="email" formControlName="email" />
                        <div *ngIf="signUpForm.get('email').invalid && signUpForm.get('email').touched" class="col-12 col-sm-8">
                            <small *ngIf="signUpForm.get('email').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                            <small *ngIf="signUpForm.get('email').errors.email && signUpForm.get('email').touched" class="error-text text-danger">
                                <span>Please enter a valid email address.<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="register_password">Password</label>
                        <input class="form-control" type="password" id="register_password" name="password" formControlName="password" />
                        <div
                            *ngIf="signUpForm.get('password').invalid && (signUpForm.get('password').dirty || signUpForm.get('password').touched)"
                            class="col-12"
                        >
                            <small *ngIf="signUpForm.get('password').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                            <small *ngIf="signUpForm.get('password').errors.minlength && signUpForm.get('password').touched" class="error-text text-danger">
                                <span>Your password should be at least 10 characters<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="register_repeat_password">Repeat Password</label>
                        <input class="form-control" type="password" id="register_repeat_password" name="repeatPassword" formControlName="repeatPassword" />
                        <div *ngIf="signUpForm.get('repeatPassword').invalid && signUpForm.get('repeatPassword').touched" class="col-12 col-sm-8">
                            <small *ngIf="signUpForm.get('repeatPassword').errors.required" class="error-text text-danger">
                                <span>This value should not be blank.<br /></span>
                            </small>
                            <small
                                *ngIf="signUpForm.get('repeatPassword').errors.minLength && signUpForm.get('repeatPassword').touched"
                                class="error-text text-danger"
                            >
                                <span>Your password should be at least 10 characters<br /></span>
                            </small>
                            <small
                                *ngIf="signUpForm.get('repeatPassword').errors.confirmedValidator && signUpForm.get('repeatPassword').touched"
                                class="error-text text-danger"
                            >
                                <span>The password fields must match.<br /></span>
                            </small>
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <p class="m-1 fw-light">I am registering as part of a:</p>
                        <div class="d-flex flex-column align-items-start justify-content-between">
                            <div>
                                <input
                                    class="form-check-input me-2 mb-1"
                                    type="radio"
                                    id="family-contact"
                                    name="contactType"
                                    formControlName="contactType"
                                    value="family"
                                />
                                <label class="form-label d-inline" for="family-contact">Family</label>
                            </div>
                            <div>
                                <input
                                    class="form-check-input me-2 mb-1"
                                    type="radio"
                                    id="school-contact"
                                    name="contactType"
                                    formControlName="contactType"
                                    value="school"
                                />
                                <label class="form-label d-inline" for="school-contact">School</label>
                            </div>
                            <div>
                                <input
                                    class="form-check-input me-2"
                                    type="radio"
                                    id="business-contact"
                                    name="contactType"
                                    formControlName="contactType"
                                    value="business"
                                />
                                <label class="form-label d-inline" for="business-contact">Business</label>
                            </div>
                        </div>
                    </div>
                    <re-captcha formControlName="recaptchaToken" [siteKey]="environment.recaptcha_site_key"></re-captcha>

                    <div class="d-flex mt-4">
                        <input type="submit" value="Register" class="btn btn-primary ms-0 mb-4" [disabled]="!signUpForm.valid || loading" />
                    </div>
                </ng-container>
                <ng-container *ngIf="success">
                    <div class="alert alert-info text-break" role="alert" *ngIf="success">
                        Your new account has been created. You can <a routerLink="/login">login here</a>.
                    </div>
                </ng-container>

                <p class="my-0 small">
                    <a routerLink="/login"><i class="fa fa-arrow-left text-primary"></i> Back to login</a>
                </p>
            </form>
        </div>
    </div>
</div>
